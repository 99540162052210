.main-page{
    height: 100vh;
    background-color: #000;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.main-page::-webkit-scrollbar{
    display: none;
}


.loading-view{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.3);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-page .body-container{
    height: calc(100% - 135px - 64px);
    padding-top: 32px;
    padding-bottom: 32px;
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.main-page .column-left{
    position: relative;
    width: 360px;
    height: 555px;
}

.main-page .android-frame{
    height: 530px;
    width: auto;
}

.main-page .iphone-frame-filled{
    position: absolute;
    top: 15px;
    left: 60px;
    height: 530px;
    width: auto;
}

.main-page .iphone-screenshot{
    position: absolute;
    top: 15px;
    left: 60px;
    height: 530px;
    width: auto;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.main-page .iphone-screenshot.visible{
    opacity: 1;
}

.main-page .column-right{
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-page .company-logo-img{
    height: 50px;
    width: auto;
}

.main-page .column-right .one-liner{
    color: #f9f9f9;
    font-size: 30px;
    font-weight: 500;
    line-height: 135%;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
}

.main-page .column-right .get-app-text{
    color: #fff;
    font-size: 14px;
    line-height: 18px;
}

.main-page .column-right .get-app-btns-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 10px;
}

.main-page .column-right img{
    height: 40px;
    width: auto;
}

.main-footer-container{
    height: 135px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
}

.main-footer-container .line-one,
.main-footer-container .line-two{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.main-footer-container .line-one{
    gap: 12px;
    margin-bottom: 20px;
}

.main-footer-container .footer-text{
    color: rgb(168, 168, 168);
    font-size: 12px;
}

.main-footer-container .socials-container{
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    gap: 12px;
}

@media (max-height: 755px){
    .main-page .body-container{
        height: 555px;
    }

    .main-page{
        height: auto;
    }
}

@media (max-width: 799px){
    .main-page .column-left{
        display: none;
    }
}

@media only screen and (max-device-width: 600px) {

}