.header{
    position: absolute;
    height: 70px;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(219, 219, 219);
}

.header .body-sub-2{
    position: relative;
    height: 70px;
    flex-direction: row;
    align-items: center !important;
}

.header .hdr-jc{
    display: flex;
    background-color: #fff;
    color: #1c2e52;
    padding: 5px 16px 6px 16px;
    border-radius: 16px;
}

.footer{
    padding-top: 50px;
    padding-bottom: 20px;
    background-color: #F7F7F7;
    opacity: 0.8;
    font-size: 15px;
}

.footer .ftr-body .web-clm-1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer .ftr-body .web-clm-1 img{
    height: 34px;
    width: 79.3px;
    opacity: 0.7;
}

.footer .ftr-body .web-clm-1 .cr-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer .ftr-body .web-clm-1 .cr-container svg{
    margin-right: 3px;
}

.footer .ftr-body .web-clm-2{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
}

.footer .ftr-body .web-clm-2 .wc-2-1 h3{
    margin-bottom: 8px;
    font-weight: 600;
}

.footer .ftr-body .web-clm-2 .wc-2-1 a{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    line-height: 25px;
}

.footer .ftr-body .web-clm-2 .wc-2-1 a svg{
    margin-right: 5px;
    margin-bottom: 0;
}

.footer .ftr-body .web-clm-2 .wc-2-1 a:hover{
    cursor: pointer;
}

.header{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    background-color: transparent;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 100;
}

.header-scrolled{
    background-color: #fff;
    border-bottom: 1px solid #ebebeb;
}

.header-scrolled .hdr-explore{
    color: #23272a !important;
    font-weight: 500 !important;
}

.header-scrolled .hdr-explore svg{
    stroke: #23272a;
    color: #23272a;
}

.header .hdr-body, .footer .ftr-body{
    width: 1120px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.header-logo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .header-logo img{
    height: 44px;
}

.header .page-link,
.footer .page-link{
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .page-link{
    display: flex;
    margin-top: 4px;
    padding: 20px 15px;
    height: 62px;
    color: #fff;
    font-size: 15px;
    letter-spacing: 0.04em;
    border-radius: 2px;
    transition: all ease-in-out 0.25s;
}

.header .page-link:hover{
    color: #fff;
    opacity: 0.8;
    cursor: pointer;
}

.dropdown-menu{
    --bs-dropdown-link-active-bg: inherit!important;
    --bs-dropdown-link-active-color: #212529!important;
}

.app-section-body{
    border-radius: 12px;
    margin: 100px auto 20px auto;
    width: 1120px;
}

.app-section-body img{
    height: 200px;
}

.lazy-loader-img {
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s; /* You can adjust the duration as needed */
}
  
.lazy-loader-img.loaded {
    opacity: 1;
}

.loading-page{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(60, 60, 60, 0.4);
    z-index: 1000;
}

.page-is-loading{
    overflow: hidden;
}

.counter {
    transition: all 0.1s ease-in-out;
}

.signed-user-acount-btn-div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.signed-user-acount-btn-div .lazy-loader-img{
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 100%;
}


.header-profile-dropdown-item.profile img{
    height: 30px;
    width: 30px;
    border-radius: 100%;
    outline: 1px solid rgba(0, 0, 0, .1);
    outline-offset: -1px;
}

.header-profile-dropdown-item .mantine-Menu-itemLabel{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-profile-dropdown-item .icon-cover{
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
    border-radius: 100%;
}

.header-profile-dropdown-item p{
    margin: 0 0 0 10px;
}

.header-profile-dropdown-item svg {
    stroke: rgb(19 19 19);
    color: rgb(19 19 19);
}

.body-sub-2 .mantine-1gt7cpf{
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1);
}

.signed-user-acount-btn-div:hover{
    cursor: pointer;
}

.signed-user-acount-btn-div img{
    height: 44px;
    width: 44px;
    border-radius: 100%;
    outline: 1px solid rgba(0, 0, 0, .1);
    outline-offset: -1px;
}

.header-action-btn-div{
    position: relative;
    height: 44px;
    width: 44px;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;
    border-radius: 100%;
    background-color: #efefef;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
}

.header-action-btn-div:hover{
    cursor: pointer;
}

.body-sub-2 .ab-margin-left{
    margin-left: 10px;
}

.mt-bl-count{
    position: absolute;
    top: -6px;
    right: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    min-width: 18px;
    border-radius: 20px;
    background-color: #e41e3f;
    color: #fff;
    font-size: 12px;
}

@media (max-width: 1250px){
    .header .hdr-body, .footer .ftr-body{
        width: 100%;
        padding: 0 2%;
    }

    .body-sub-2 .mantine-1gt7cpf{
        right: 0px;
        left: unset !important;
    }
}

@media (max-width: 1100px){
    .header .page-link{
        margin-right: 0;
    }

    .app-section-body{
        width: 96%;
        margin-left: 2%;
        margin-right: 2%;
    }
}

@media (max-width: 799px){
    .hdr-body{
        width: 100%;
        padding: 0 1%;
    }
}

@media only screen and (max-device-width: 600px){
    .hdr-body{
        width: 100%;
        padding: 0 2%;
    }

    .header .page-link{
        height: 33px;
        padding: 0 5px;
        font-size: 16px;
        margin-top: 0;
    }

    .header .non-mobile{
        display: none;
    }

    .header .page-link:hover{
        opacity: 1;
    }

    .footer{
        height: auto;
        box-shadow: none;
        flex-direction: column;
        padding: 0 2% 10px 2%;
    }
    
    .footer .page-link, .footer .copywrite{
        height: 50px;
    }
    
    .copywrite{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header .hdr-explore{
        padding-right: 15px;
    }

    .header .hdr-jc{
        padding: 5px 16px;

        & svg{
            display: none;
        }
    }

    .header .body-sub-2 .mantine-Popover-dropdown{
        display: none;
    }

    .body-sub-2 .ab-margin-left{
        margin-left: 5px;
    }

    .signed-user-acount-btn-div{
        margin-left: 5px;
    }

    .header .hdr-body, 
    .footer .ftr-body {
        padding: 0 4%;
    }
}