.travel-passport-container{
    position: relative;
}

.wn-subscribe{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 20px;
    height: 36px;
    font-weight: 600;
    letter-spacing: 0.045em;
    color: #fff;
    transition: all 0.3s ease;
}

.wn-subscribe svg{
    margin-right: 2px;
}

.wl-success-div .wn-lc svg{
    margin-right: 2px;
    animation: arrow-bounce-scale 2s infinite;
}

.wn-subscribe span{
    margin-right: 2px;
}

.wn-subscribe:hover{
    cursor: pointer;
}

.wn-subscribe svg{
    margin-left: 5px;
}

.customer-testimonial-modal h2{
    text-align: center;
    font-size: 24px;
    margin-bottom: 15px;
    margin-top: 20px;
    letter-spacing: 0.02em;
    font-weight: 600;
}

.customer-testimonial-modal .ctm-hdr-body{
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.025em;
    font-weight: 500;
}

.customer-testimonial-modal .ctm-hdr-body span{
    font-weight: 600;
}

.hth-input{
    position: relative;
}

.hth-input input{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    width: 70%;
    margin-bottom: 20px;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
    transition: all 0.25s ease-in-out;
}

.hth-input input:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 1px rgb(63 163 240 / 30%), 0 1px 1px 0 rgb(0 0 0 / 7%), 0 0 0 3px rgb(63 163 240 / 30%) !important;
}

.hth-input input::placeholder{
    color: #000;
}

.hth-input.error .helper-txt,
.subscribe-interests-container .helper-txt{
    position: absolute;
    display: flex;
    left: 3px;
    bottom: 2px;
    color: #e70000;
    font-size: 12px;
    z-index: 1;
}

.hth-input.error input{
    border-color: #e70000;
}

.subscribe-interests-container .helper-txt{
    bottom: -18px;
}

.customer-testimonial-modal .wn-subscribe{
    margin-left: auto;
    margin-right: auto;
}

.customer-testimonial-modal h4{
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 5px;
}

.customer-testimonial-modal .wn-subscribe{
    margin-top: 30px;

    .wns-di svg{
        margin: 0;
    }
}

.subscribe-interests-container{
    position: relative;
}

.travel-passport-modal .ctm-hdr-body{
    margin: 20px auto 30px auto;
    width: 80%;
    line-height: 150%;
    font-size: 22px;
}

.travel-passport-modal .wn-subscribe.wn-lc{
    margin-bottom: 25px;
}

.travel-passport-modal .hth-input input{
    display: flex;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    line-height: 34px;
    padding: 3px 13px 3px 13px;
}

.travel-passport-modal .hth-input input::placeholder{
    color: #6b7c93;
}

.travel-passport-modal .hth-input input{
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    outline: none !important;
}

.hth-input.error .helper-txt{
    bottom: -17px;
    left: calc((100% - 280px)/2 + 3px);
}

.sm-btns-div .smb-header{
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}

.sm-btns-div .smb-header .smbdh-line{
    position: absolute;
    top: 10px;
    left: 15%;
    height: 2px;
    width: 70%;
    background-color: #f7f7f7;
    z-index: 0;
}

.sm-btns-div .smb-header p{
    width: auto;
    margin: 0;
    padding: 0 8px;
    background-color: #fff;
    font-size: 13px;
    z-index: 10;
}

.sm-btns-div .container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.wn-subscribe:disabled {
    cursor: not-allowed;
}

.travel-passport-modal .ct-close-btn{
    position: absolute;
    top: 8px;
    right: 10px;
    padding: 5px;
}

.awm-cs-txt{
    position: absolute;
    bottom: -18px;
    left: 37px;
    color: rgb(148, 148, 148);
    font-size: 12px;
}

@media (max-width: 799px){
    .wn-subscribe{
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-device-width: 600px) {
    .wn-subscribe{
        margin-left: inherit;
        margin-right: inherit;
    }

    .wn-subscribe:hover{
        background-color: rgba(63, 163, 240, 1);
    }

    .hth-input input{
        font-size: 16px;
    }

    .travel-passport-modal .ctm-hdr-body{
        width: 96%;
    }

    .hth-input.error .helper-txt{
        left: calc((100% - 240px)/2 + 3px);
    }
}

@keyframes arrow-bounce-scale {
    0%, 20%, 50%, 80%, 100% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.3);
    }
    60% {
        transform: scale(1.1);
    }
}