.terms-privacy-main{
    padding-top: 70px;
}

.terms-privacy-main .header .page-link{
    color: #23272a;
    font-weight: 500;
}

.terms-privacy-main .header {
    position: fixed;
    top: 0;
    left: 0;
    border: none;
    border-bottom: 1px solid #ebebeb;
    background-color: #fff;
    transition: all 0.3s ease;
}

.terms-privacy-container{
    flex-direction: column;
    margin: 0px auto;
    width: 680px;
    letter-spacing: .025em;
}

.terms-privacy-container .os-header{
    margin-top: 10px;
    font-weight: 600;
    font-size: clamp(28px,2.5vw,42px);
}

.terms-privacy-container .os-header-footer{
    margin-top: 5px;
    color: #737373;
    font-size: 14px;
}

.terms-privacy-container .os-question{
    margin-top: 40px;
    font-weight: 600;
    color: #262626;
    font-size: 20px;
}

.terms-privacy-container .os-response{
    margin-top: 16px;
    color: rgba(0, 0, 0, .9);
    font-size: 17px;
    line-height: 1.625em;
}

.terms-privacy-container .os-response-ul{
    list-style-type: disc;
    padding: 0 12px;
}

.terms-privacy-container .os-response-li{
    color: rgba(0, 0, 0, .9);
    font-size: 17px;
    line-height: 1.625em;
    margin: 16px 20px 16px 20px;
}

.terms-privacy-container .os-rfs{
    margin-top: 20px;
    margin-bottom: 40px;
    text-decoration: underline !important;
    color: rgba(0, 0, 0, .9);
    font-size: clamp(16px,2vw,18px);
    line-height: 1.525em;
}

@media (max-width: 749px){
    .terms-privacy-container{
        width: 100%;
        padding: 0 4%;
    }

    .terms-privacy-container{
        margin-bottom: 60px;
    }
}

@media only screen and (max-device-width: 600px) {

}